import payload_plugin_BwjP8ARzzD from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3___rollup@4.32.0/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_ThWOYEfRJn from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.10.10_db0@0.2.1_drizzle-orm@0.38.3_postgres@_phicj2564n4tjlvwjkmbmbp5ry/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_76Pme5d4zW from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.10.10_db0@0.2.1_drizzle-orm@0.38.3_postgres@_phicj2564n4tjlvwjkmbmbp5ry/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Gu5hbnXf8k from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.10.10_db0@0.2.1_drizzle-orm@0.38.3_postgres@_phicj2564n4tjlvwjkmbmbp5ry/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import sentry_client_UqkV5Ao7fb from "/vercel/path0/node_modules/.pnpm/@develit-io+nuxt-sentry@0.4.2_magicast@0.3.5_rollup@4.32.0_vue@3.5.13_typescript@5.7.3_/node_modules/@develit-io/nuxt-sentry/dist/runtime/sentry.client.mjs";
import _0_siteConfig_gpPHR4YQXA from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@3.0.6_magicast@0.3.5_rollup@4.32.0_vite@6.0.11_@types+node@22.10.10_jiti@2.4_25tgbdkulmw7ill3ako6wc2cgi/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import navigation_repaint_client_F28qmT4Gju from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.10.10_db0@0.2.1_drizzle-orm@0.38.3_postgres@_phicj2564n4tjlvwjkmbmbp5ry/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8LTg0TyzTV from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.10.10_db0@0.2.1_drizzle-orm@0.38.3_postgres@_phicj2564n4tjlvwjkmbmbp5ry/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_zIKziYOrrW from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.10.10_db0@0.2.1_drizzle-orm@0.38.3_postgres@_phicj2564n4tjlvwjkmbmbp5ry/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_53oHI1IsTV from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3___rollup@4.32.0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_iYEQILHaJN from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.10.10_db0@0.2.1_drizzle-orm@0.38.3_postgres@_phicj2564n4tjlvwjkmbmbp5ry/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_RnirFIEwrV from "/vercel/path0/node_modules/.pnpm/nuxt-easy-lightbox@1.0.2_magicast@0.3.5_rollup@4.32.0_vue@3.5.13_typescript@5.7.3_/node_modules/nuxt-easy-lightbox/dist/runtime/plugin.mjs";
import siteConfig_WSdcDfLv7Y from "/vercel/path0/node_modules/.pnpm/nuxt-seo-utils@6.0.8_magicast@0.3.5_rollup@4.32.0_vite@6.0.11_@types+node@22.10.10_jiti@2.4.2_oh74nvcqemko4sgntjvztgk724/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/siteConfig.js";
import inferSeoMetaPlugin_OvpiC9gShp from "/vercel/path0/node_modules/.pnpm/nuxt-seo-utils@6.0.8_magicast@0.3.5_rollup@4.32.0_vite@6.0.11_@types+node@22.10.10_jiti@2.4.2_oh74nvcqemko4sgntjvztgk724/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/inferSeoMetaPlugin.js";
import titles_VMRQxeiKei from "/vercel/path0/node_modules/.pnpm/nuxt-seo-utils@6.0.8_magicast@0.3.5_rollup@4.32.0_vite@6.0.11_@types+node@22.10.10_jiti@2.4.2_oh74nvcqemko4sgntjvztgk724/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/titles.js";
import plugin_kn3XtG8oDr from "/vercel/path0/node_modules/.pnpm/nuxt-directus@5.7.0_magicast@0.3.5_rollup@4.32.0/node_modules/nuxt-directus/dist/runtime/plugin.mjs";
import plugin_client_tN72JH81xj from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5_rollup@4.32.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import unocss_MzCDxu9LMj from "/vercel/path0/.nuxt/unocss.mjs";
import scroll_S7pZiC3d46 from "/vercel/path0/plugins/scroll.ts";
import defaults_w9zMqp5MMp from "/vercel/path0/node_modules/.pnpm/nuxt-seo-utils@6.0.8_magicast@0.3.5_rollup@4.32.0_vite@6.0.11_@types+node@22.10.10_jiti@2.4.2_oh74nvcqemko4sgntjvztgk724/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/defaults.js";
export default [
  payload_plugin_BwjP8ARzzD,
  revive_payload_client_ThWOYEfRJn,
  unhead_76Pme5d4zW,
  router_Gu5hbnXf8k,
  sentry_client_UqkV5Ao7fb,
  _0_siteConfig_gpPHR4YQXA,
  navigation_repaint_client_F28qmT4Gju,
  check_outdated_build_client_8LTg0TyzTV,
  chunk_reload_client_zIKziYOrrW,
  plugin_vue3_53oHI1IsTV,
  components_plugin_KR1HBZs4kY,
  prefetch_client_iYEQILHaJN,
  plugin_RnirFIEwrV,
  siteConfig_WSdcDfLv7Y,
  inferSeoMetaPlugin_OvpiC9gShp,
  titles_VMRQxeiKei,
  plugin_kn3XtG8oDr,
  plugin_client_tN72JH81xj,
  unocss_MzCDxu9LMj,
  scroll_S7pZiC3d46,
  defaults_w9zMqp5MMp
]