
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as _91slug_93QMdSQiQcFkMeta } from "/vercel/path0/pages/kategorie/[slug].vue?macro=true";
import { default as indexvYPbR6M240Meta } from "/vercel/path0/pages/kategorie/index.vue?macro=true";
import { default as kontaktgUfu4Sf1oqMeta } from "/vercel/path0/pages/kontakt.vue?macro=true";
import { default as o_45nasUroN3GGTBZMeta } from "/vercel/path0/pages/o-nas.vue?macro=true";
import { default as _91uuid_93AMlO2WGdfWMeta } from "/vercel/path0/pages/objednavka/[uuid].vue?macro=true";
import { default as index52v1IZ46xaMeta } from "/vercel/path0/pages/objednavka/index.vue?macro=true";
import { default as _91slug_93UE1vzW9xUQMeta } from "/vercel/path0/pages/produkt/[slug].vue?macro=true";
import { default as realizacejzqM7r9vqqMeta } from "/vercel/path0/pages/realizace.vue?macro=true";
import { default as component_45stubk6nv7uv2CgMeta } from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.10.10_db0@0.2.1_drizzle-orm@0.38.3_postgres@_phicj2564n4tjlvwjkmbmbp5ry/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubk6nv7uv2Cg } from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.10.10_db0@0.2.1_drizzle-orm@0.38.3_postgres@_phicj2564n4tjlvwjkmbmbp5ry/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "kategorie-slug",
    path: "/kategorie/:slug()",
    component: () => import("/vercel/path0/pages/kategorie/[slug].vue")
  },
  {
    name: "kategorie",
    path: "/kategorie",
    component: () => import("/vercel/path0/pages/kategorie/index.vue")
  },
  {
    name: "kontakt",
    path: "/kontakt",
    component: () => import("/vercel/path0/pages/kontakt.vue")
  },
  {
    name: "o-nas",
    path: "/o-nas",
    component: () => import("/vercel/path0/pages/o-nas.vue")
  },
  {
    name: "objednavka-uuid",
    path: "/objednavka/:uuid()",
    component: () => import("/vercel/path0/pages/objednavka/[uuid].vue")
  },
  {
    name: "objednavka",
    path: "/objednavka",
    component: () => import("/vercel/path0/pages/objednavka/index.vue")
  },
  {
    name: "produkt-slug",
    path: "/produkt/:slug()",
    component: () => import("/vercel/path0/pages/produkt/[slug].vue")
  },
  {
    name: "realizace",
    path: "/realizace",
    component: () => import("/vercel/path0/pages/realizace.vue")
  },
  {
    name: "category-page",
    path: "/kategorie/:slug/:page(\\d+)?",
    component: () => import("/vercel/path0/pages/kategorie/[slug].vue")
  },
  {
    name: component_45stubk6nv7uv2CgMeta?.name,
    path: "/xml-feed/zbozi.xml",
    component: component_45stubk6nv7uv2Cg
  },
  {
    name: component_45stubk6nv7uv2CgMeta?.name,
    path: "/xml-feed/heureka.xml",
    component: component_45stubk6nv7uv2Cg
  }
]